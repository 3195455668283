exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-abortion-laws-by-country-js": () => import("./../../../src/templates/abortion-laws-by-country.js" /* webpackChunkName: "component---src-templates-abortion-laws-by-country-js" */),
  "component---src-templates-abortion-pill-between-10-and-13-weeks-js": () => import("./../../../src/templates/abortion-pill-between-10-and-13-weeks.js" /* webpackChunkName: "component---src-templates-abortion-pill-between-10-and-13-weeks-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-ads-pages-js": () => import("./../../../src/templates/ads-pages.js" /* webpackChunkName: "component---src-templates-ads-pages-js" */),
  "component---src-templates-after-js": () => import("./../../../src/templates/after.js" /* webpackChunkName: "component---src-templates-after-js" */),
  "component---src-templates-before-js": () => import("./../../../src/templates/before.js" /* webpackChunkName: "component---src-templates-before-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-courses-archive-js": () => import("./../../../src/templates/courses-archive.js" /* webpackChunkName: "component---src-templates-courses-archive-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-howto-js": () => import("./../../../src/templates/howto.js" /* webpackChunkName: "component---src-templates-howto-js" */),
  "component---src-templates-medical-abortion-js": () => import("./../../../src/templates/medical-abortion.js" /* webpackChunkName: "component---src-templates-medical-abortion-js" */),
  "component---src-templates-single-country-js": () => import("./../../../src/templates/single-country.js" /* webpackChunkName: "component---src-templates-single-country-js" */),
  "component---src-templates-single-course-js": () => import("./../../../src/templates/single-course.js" /* webpackChunkName: "component---src-templates-single-course-js" */)
}

